import { clsx } from 'clsx';
import { getStaticAssetUrl } from '@innhold/lib/assets/getStaticAssetUrl';
import {
    DATA_ENTRY_COMPANY,
    DATA_ENTRY_TITLE,
    DATA_ENTRY_URL,
    STRIPE_CUSTOM_ENTRY_CLASS_NAME,
} from './constants';
import { trackCustomEntryClick, trackEntryClick } from './tracking';
import type { CustomStripeEntry, NewsStripeEntry, StripeEntry } from './types';
import css from './AksjeliveStripe.module.css';

const CustomEntry = ({ entry }: { entry: CustomStripeEntry }) => {
    const { company, image, title, url } = entry;
    const customEntryDataAttributes = {
        [DATA_ENTRY_COMPANY]: company,
        [DATA_ENTRY_TITLE]: title,
        [DATA_ENTRY_URL]: url,
    };
    return (
        <li
            className={clsx(STRIPE_CUSTOM_ENTRY_CLASS_NAME, css.customEntry)}
            {...customEntryDataAttributes}
        >
            <span className={css.heading}>Annonse | {company}</span>
            <a
                className={css.link}
                href={url}
                draggable="false"
                rel="noreferrer"
                target="_blank"
                onClick={() => trackCustomEntryClick(entry)}
            >
                <div className={css.content}>
                    <p className={css.title}>{title}</p>
                    {image ? (
                        <div className={css.image}>
                            <img src={getStaticAssetUrl(image.url)} alt={image.altText} />
                        </div>
                    ) : null}
                </div>
            </a>
        </li>
    );
};

const DefaultEntry = ({ entry }: { entry: NewsStripeEntry }) => {
    const { displayTimestamp, dateMonthYearTimestamp: fullTimestamp, title, url } = entry;
    return (
        <li>
            <span className={css.timestamp}>{displayTimestamp}</span>
            <span className={css.fullTimestamp}>{fullTimestamp}</span>
            <a
                className={css.link}
                href={url}
                draggable="false"
                onClick={() => trackEntryClick(entry)}
            >
                <div className={css.content}>
                    <p className={css.title}>{title}</p>
                </div>
            </a>
        </li>
    );
};

export const AksjeliveStripeEntry = ({ entry }: { entry: StripeEntry }) => {
    if (entry.type === 'custom') {
        return <CustomEntry entry={entry} />;
    }

    return <DefaultEntry entry={entry} />;
};
