import { useCallback, useRef } from 'react';

export function useScrollTracking({
    scrollTrackingMilestone = 0,
    trackScrollFn,
}: {
    scrollTrackingMilestone?: number;
    trackScrollFn: () => void;
}) {
    const hasReachedMilestoneRef = useRef(false);
    const trackScroll = useCallback(
        (scrollPercentage: number) => {
            if (scrollPercentage >= scrollTrackingMilestone) {
                const { current: hasReachedMilestone } = hasReachedMilestoneRef;
                if (!hasReachedMilestone) {
                    trackScrollFn();
                }

                hasReachedMilestoneRef.current = true;
            } else {
                hasReachedMilestoneRef.current = false;
            }
        },
        [scrollTrackingMilestone, trackScrollFn]
    );

    return trackScroll;
}
