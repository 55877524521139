import { useRef, useState } from 'react';
import { clsx } from 'clsx';
import { useHorizontalDragScroll } from '@innhold/lib/hooks/useHorizontalDragScroll';
import { useScrollTracking } from '@innhold/lib/hooks/useScrollTracking';
import { AksjeliveStripeEntry } from './AksjeliveStripeEntry';
import { useCustomEntriesViewTracker } from './hooks/useCustomEntriesViewTracker';
import { trackArrowClick, trackStripeScroll } from './tracking';
import type { StripeEntry } from './types';
import css from './AksjeliveStripe.module.css';

export const AksjeliveStripe = ({ entries }: { entries: StripeEntry[] }) => {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    const stripeScrollerRef = useRef<HTMLUListElement>(null);
    const { eventHandlers: dragScrollEventHandlers, isDragging } =
        useHorizontalDragScroll(stripeScrollerRef, { scrollFactor: 1.8 });
    const trackScroll = useScrollTracking({
        scrollTrackingMilestone: 10,
        trackScrollFn: trackStripeScroll,
    });

    useCustomEntriesViewTracker();

    const onStripeArrowClick = (scrollDirection: 'left' | 'right') => {
        const { current: scroller } = stripeScrollerRef;

        scroller.scrollBy({
            left: scrollDirection === 'left' ? -400 : 400,
            behavior: 'smooth',
        });
        trackArrowClick(scrollDirection);
    };

    const onStripeScroll = () => {
        const { current: scroller } = stripeScrollerRef;
        const maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;
        const newScrollPercentage = (scroller.scrollLeft / maxScrollLeft) * 100;

        setScrollPercentage(newScrollPercentage);
        trackScroll(newScrollPercentage);
    };

    const canScrollLeft = scrollPercentage > 5;
    const canScrollRight = scrollPercentage < 95;

    return entries ? (
        <div className={css.aksjeliveStripeContainer}>
            {canScrollLeft ? (
                <button
                    className={clsx(css.aksjeliveStripeArrow, css.stripeArrowLeft)}
                    onClick={() => onStripeArrowClick('left')}
                >
                    ←
                </button>
            ) : null}
            <div
                className={clsx(css.aksjeliveStripeViewport, {
                    [css.withLeftGradient]: canScrollLeft,
                    [css.withRightGradient]: canScrollRight,
                })}
            >
                <ul
                    ref={stripeScrollerRef}
                    className={clsx(css.aksjeliveStripeScroller, {
                        [css.dragging]: isDragging,
                    })}
                    {...dragScrollEventHandlers}
                    onScroll={onStripeScroll}
                >
                    {entries.map((entry) => {
                        const { id: key } = entry;
                        return <AksjeliveStripeEntry key={key} entry={entry} />;
                    })}
                </ul>
            </div>
            {canScrollRight ? (
                <button
                    className={clsx(css.aksjeliveStripeArrow, css.stripeArrowRight)}
                    onClick={() => onStripeArrowClick('right')}
                >
                    →
                </button>
            ) : null}
            <div className={css.scrollbar}>
                <div
                    className={css.scrollbarThumb}
                    style={{ width: `${scrollPercentage}%` }}
                />
            </div>
        </div>
    ) : null;
};
