import { useEffect } from 'react';
import { createViewObserverWithTimeout } from '@innhold/core/tracking/utils/createViewObserver';
import {
    DATA_ENTRY_COMPANY,
    DATA_ENTRY_TITLE,
    DATA_ENTRY_URL,
    STRIPE_CUSTOM_ENTRY_CLASS_NAME,
} from '../constants';
import { trackCustomEntryView } from '../tracking';

export const useCustomEntriesViewTracker = () => {
    useEffect(() => {
        const intersectionObserver = createViewObserverWithTimeout(
            `.${STRIPE_CUSTOM_ENTRY_CLASS_NAME}`,
            (target) => {
                const company = target.getAttribute(DATA_ENTRY_COMPANY);
                const title = target.getAttribute(DATA_ENTRY_TITLE);
                const url = target.getAttribute(DATA_ENTRY_URL);

                trackCustomEntryView({ company, title, url });
            }
        );
        return () => {
            intersectionObserver.disconnect();
        };
    }, []);
};
